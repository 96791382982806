<template>
  <v-container fluid>
    <v-row id="content">
      <v-col cols="12">
        <v-row>
          <h1
            v-html="$t('breakInInstructions.title')"
            :class="{ 'margin-small': $vuetify.breakpoint.mdAndUp }"
          ></h1>
        </v-row>
        <v-row v-if="videos.length > 0" class="pb-5">
          <template v-for="(item, i) in videos">
            <v-col
              cols="12"
              :sm="smCols"
              :key="`${i}-video`"
              class="video-container"
              align="center"
              justify="center"
            >
              <iframe
                title="vimeo-player"
                :src="`https://player.vimeo.com/video/${item}`"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </v-col>
          </template>
        </v-row>
        <v-row>
          <p
            v-html="getContent($t('breakInInstructions.content'))"
            :class="{ 'margin-small': $vuetify.breakpoint.mdAndUp }"
          ></p>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    videos() {
      const content = this.$t("breakInInstructions.videos");

      if (content) {
        const list = JSON.parse(JSON.stringify(content));

        if (list && list.length > 0) {
          return list;
        }
      }

      return [];
    },
    smCols() {
      if (this.videos && this.videos.length > 1) {
        return parseInt(12 / this.videos.length);
      } else {
        return 12;
      }
    },
  },

  methods: {
    getContent(content) {
      return content.join("\n");
    },
  },
};
</script>
